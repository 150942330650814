<template>
  <v-menu
    dense
    v-model="displayResults"
    nudge-bottom="52"
    content-class="elevation-1"
    max-height="360"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- SearchField -->
      <v-text-field
        dense
        hide-details
        class="pb-2"
        flat
        :label="placeholder || $t('organisations.searchForSomeone')"
        prepend-inner-icon="mdi-magnify"
        filled
        solo
        v-model="search"
        clearable
        @click:clear="clearSearch"
        @input="displayResults = true"
        v-bind="attrs"
        v-on="on"
        autocomplete="off"
      ></v-text-field>
    </template>
    <v-card color="light_grey" class="pa-0">
      <!-- Loading Progress Bar -->
      <v-card-title class="pa-0">
        <v-divider></v-divider>
        <v-progress-linear
          :indeterminate="usersQueryLoading || groupsQueryLoading"
          :active="usersQueryLoading || groupsQueryLoading"
          color="button_blue"
        ></v-progress-linear>
        <v-subheader
          >{{ $t("misc.results") }} ({{ filteredItems.length }}):</v-subheader
        >
      </v-card-title>

      <v-list avatar dense depressed color="light_grey" class="pa-0 pb-2 pt-0">
        <template v-if="usersQueryLoading || groupsQueryLoading">
          <v-skeleton-loader
            v-for="n in 4"
            :key="'skl_usr_' + n"
            class="skeleton_list"
            type="list-item-avatar"
          ></v-skeleton-loader>
        </template>
        <template v-else>
          <!-- No users found -->
          <v-row
            wrap
            no-gutters
            justify="center"
            v-if="filteredItems.length == 0"
            class="pb-4"
          >
            <span class="caption">{{ $t("misc.noResults") }}</span>
          </v-row>
          <v-list-item-group
            v-else
            :value="selectedItems"
            color="primary"
            multiple
          >
            <v-list-item
              @click="clickOnItem(item, itemIndex)"
              dense
              v-for="(item, itemIndex) in filteredItems"
              :key="'__' + itemIndex"
              style="height: 40px"
            >
              <template v-slot:default="{ active, toggle }">
                <v-list-item-avatar>
                  <userAvatar
                    v-if="item.type == 'user'"
                    :size="24"
                    :show_name_in_tooltip="false"
                    :first_name="item.data.user.first_name"
                    :last_name="item.data.user.last_name"
                    :avatar_url="item.data.user.avatar_url"
                    :email="item.data.user.email"
                  />

                  <v-avatar v-if="item.type == 'group'" size="24">
                    <v-icon size="12" class="main_blue" color="white" dark>
                      mdi-account-group
                    </v-icon></v-avatar
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action style="display: contents" v-if="active">
                  <span class="caption">{{ selectedMessage }}</span>
                  <!-- active: {{active}} toggle: {{toggle}} -->
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list-item-group>
        </template>
      </v-list>
    </v-card>
  </v-menu>
               
</template>

<script>
import { mapGetters } from "vuex";
import PAGINATED_ORGANISATION_MEMBERS_INDEX from "@/graphql/organisations/paginated_members_index.gql";
import GROUPS_INDEX from "@/graphql/groups/index.gql";
import userAvatar from "../shared/userAvatar";

export default {
  name: "searchAndSelectUsers",
  props: {
    selectedUsersIds: {
      type: Array,
    },
    displayGroups: {
      type: Boolean,
      default: false,
    },
    selectedMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      required: false
    },
    disabledIds: {
      type: Array,
      required: false,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    userAvatar,
  },
  data() {
    return {
      search: "",
      displayResults: false,
      groupsQueryLoading: false,
      usersQueryLoading: false,
      groups: [],
      //users: [],
    };
  },
  computed: {
    ...mapGetters(["selectedOrganisationId", "organisation_users", "users_set"]),
    filteredOrganisationUser() {
      let ret = this.organisation_users.filter(e => !e.excluded).map(e => {
        return {
          id: e.id,
          user: this.users_set[e.user_id]
        }
      })

      if (this.disabledIds.length)
        ret = ret.filter(e => !this.disabledIds.includes(e.user.id));

      if (this.search)
        return ret.filter((u) => {
          let userStr = `${u.user.last_name} ${u.user.first_name} ${u.user.email}`;
          return userStr.toLowerCase().search(this.search.toLowerCase()) != -1
        });
      return ret;
    },
    filteredGroups() {
      if (!this.displayGroups) return [];
      if (this.search)
        return this.groups.filter((g) => {
          return g.name.toLowerCase().search(this.search.toLowerCase()) != -1;
        });
      return this.groups;
    },
    filteredItems() {
      var items = [];
      this.filteredOrganisationUser.forEach((u) => {
        let name_ =
          u.user.first_name || u.user.last_name
            ? u.user.first_name + " " + u.user.last_name
            : u.user.email;
        items.push({
          type: "user",
          data: u,
          name: name_,
          ressourceId: u.user.id,
        });
      });
      this.filteredGroups.forEach((g) => {
        items.push({
          type: "group",
          data: g,
          name: g.name,
          ressourceId: g.id,
        });
      });
      // console.log(items);
      return items.sort((a, b) => 2 * (a.name > b.name) - 1);
    },
    selectedItems() {
      //highlight items in the list which are present in the selectedUsersIds array

      var _selectedItems = [];
      this.filteredItems.forEach((fi, i) => {
        if (this.selectedUsersIds.includes(fi.ressourceId))
          _selectedItems.push(i);
      });

      return _selectedItems;
    },
    organisationFilter() {
      if (!this.selectedOrganisationId) return "";
      return `organisation_id == '${this.selectedOrganisationId}'`;
    },
  },
  methods: {
    clearSearch() {
      this.search = "";
    },
    clickOnItem(item, index) {
      if (item.type == "user") {
        if (this.selectedItems.includes(index)) {
          this.$emit("onUserUnselected", item.data.user);
        } else {
          this.$emit("onUserSelected", item.data.user);
        }
      }
    },
    getData() {
      // Users
      //this.usersQueryLoading = true;
      //this.$apollo
      //  .query({
      //    query: PAGINATED_ORGANISATION_MEMBERS_INDEX,
      //    variables: {
      //      filter: this.organisationFilter,
      //      page: 1,
      //      per_page: -1,
      //      order_by: "user.first_name",
      //    },
      //  })
      //  .then(({ data }) => {
      //    //this.users = data.paginated_organisation_users.data.filter(
      //    //  (e) => !this.disabledIds.includes(e.user.id)
      //    //);
      //    console.log("users", this.users);
      //    this.usersQueryLoading = false;
      //  })
      //  .catch((e) => {
      //    console.log(e);
      //    this.usersQueryLoading = false;
      //    this.sendError(e);
      //  });

      // groups
      if (this.displayGroups) {

        this.groupsQueryLoading = true;
        this.$apollo
          .query({
            query: GROUPS_INDEX,
            variables: {
              filter: this.organisationFilter,
            },
          })
          .then(({ data }) => {
            this.groups = data.groups;
            // console.log("groups", this.groups);
            this.groupsQueryLoading = false;
          })
          .catch((e) => {
            console.log(e);
            this.groupsQueryLoading = false;
            this.sendError(e);
          });
      }
    },
  },
  mounted() {},
  watch: {
    displayResults(new_value, old_value) {
      // console.log("displayResults", new_value, old_value);
      if (new_value == true) {
        this.getData();
      }
      if (new_value == false) {
        this.search = "";
      }
    },
  },
};
</script>

<style>
.skeleton_list .v-skeleton-loader__list-item-avatar {
  background: transparent !important;
}
</style>
