<template>
  <v-list-item    dense :class="{ denseListItemFix: dense, 'px-1': condensed}" v-if="user">
    <v-list-item-avatar class="mr-2">
      <userAvatar
        :size="dense || condensed ? 24 : 32"
        :show_name_in_tooltip="false"
        :first_name="user.first_name"
        :last_name="user.last_name"
        :avatar_url="user.avatar_url"
        :email="user.email"
      />
    </v-list-item-avatar>

    <v-list-item-content>

      <v-list-item-title :class="{'body-4' : condensed}">{{
        user.first_name && user.last_name ?
        [user.first_name, user.last_name].join(" ")
        : user.email
        }}</v-list-item-title>

      <template v-if="!dense">
        <v-list-item-subtitle v-if="user.job" :class="{'body-4' : condensed}">
          {{ user.job }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else :class="{'body-4' : condensed}">
          {{$t("userProfile.notSpecified")}}
        </v-list-item-subtitle>
      </template>

      <slot name="custom">
      </slot>

    </v-list-item-content>
    <v-list-item-action v-if="!noAction">
      <slot name="action"></slot>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import userAvatar from "../shared/userAvatar";

export default {
  name: "userItem",
  components: { userAvatar },
  props: {
    user: {
      type: Object,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    noAction: {
      type: Boolean,
      default: false
    },
    condensed: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style>
.denseListItemFix {
  height: 36px
}
</style>

